import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper
} from '@mui/material';

import {
  InputText,
  InputTextarea,
  InputAddress,
  InputRadio,
  InputMask
} from '@aldridge/aldg-data-components';
import * as yup from 'yup';
import { UserContext } from '../../providers/UserProvider';

const OrganizationInformation = (props) => {
  const {
    record,
    shouldBeDisabled,
    onChange,
    disabledTheme,
    config,
    schema,
    fullyReviewed
  } = props;

  const user = useContext(UserContext);
  console.log(record.data);
  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container className='aldg-rootFormBox'>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                textDecoration: 'underline',
                marginTop: '10px',
                fontWeight: 'bold'
              }}
            >
              Organization
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputText
                label='Organization Name'
                name='OrganizationName'
                onChange={onChange}
                value={record.data.OrganizationName || ''}
                disabled={shouldBeDisabled}
                required
                customTheme={disabledTheme}
                error={
                  !yup
                    .reach(schema, 'data.OrganizationName')
                    .isValidSync(record.data.OrganizationName)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                label='FEIN'
                name='FEIN'
                onChange={onChange}
                value={record.data.FEIN || ''}
                disabled={shouldBeDisabled}
                required
                customTheme={disabledTheme}
                maskProps={{ mask: /(\d{0,2})(\d{0,7})/, delim: '-' }}
                error={
                  !yup.reach(schema, 'data.FEIN').isValidSync(record.data.FEIN)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InputAddress
                config={config}
                label='Address'
                name='Address'
                onChange={onChange}
                value={record.data.Address || ''}
                disabled={shouldBeDisabled}
                required
                customTheme={disabledTheme}
              />
            </Grid>
            {user ? (
              <>
                <Grid item xs={3}>
                  <InputText
                    label='Requested Amount'
                    name='RequestedAmount'
                    onChange={onChange}
                    value={record.data.RequestedAmount || ''}
                    disabled={shouldBeDisabled}
                    required
                    customTheme={disabledTheme}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      )
                    }}
                  />
                  <FormHelperText>
                    {Number.isNaN(parseFloat(record.data.RequestedAmount))
                      ? null
                      : `$${parseFloat(
                          record.data.RequestedAmount.replace(',', '')
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })}`}
                  </FormHelperText>
                </Grid>
                <Grid item xs={3}>
                  <InputText
                    label='Final Approved Amount'
                    name='ApprovedAmount'
                    onChange={onChange}
                    value={record.data.ApprovedAmount || ''}
                    required={fullyReviewed()}
                    customTheme={disabledTheme}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                      )
                    }}
                  />
                  <FormHelperText>
                    {Number.isNaN(parseFloat(record.data.ApprovedAmount))
                      ? null
                      : `$${parseFloat(
                          record.data.ApprovedAmount
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2
                        })}`}
                  </FormHelperText>
                </Grid>
              </>
            ) : (
              <Grid item xs={6}>
                <InputText
                  label='Requested Amount'
                  name='RequestedAmount'
                  onChange={onChange}
                  value={record.data.RequestedAmount || ''}
                  disabled={shouldBeDisabled}
                  required
                  customTheme={disabledTheme}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>$</InputAdornment>
                    )
                  }}
                />
                <FormHelperText>
                  {Number.isNaN(parseFloat(record.data.RequestedAmount))
                    ? null
                    : `$${parseFloat(
                        record.data.RequestedAmount.replace(',', '')
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2
                      })}`}
                </FormHelperText>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputTextarea
                label='Purpose/Mission of the Organization'
                name='PurposeMission'
                onChange={onChange}
                value={record.data.PurposeMission || ''}
                disabled={shouldBeDisabled}
                required
                customTheme={disabledTheme}
                error={
                  !yup
                    .reach(schema, 'data.PurposeMission')
                    .isValidSync(record.data.PurposeMission)
                }
              />
            </Grid>
            {/* <GridFillMaxHolder item xs={12}> */}
            <Grid item xs={9}>
              <InputText
                label='Website'
                name='Website'
                onChange={onChange}
                required
                value={record.data.Website || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
                // error={
                //   !yup
                //     .reach(schema, 'data.Website')
                //     .isValidSync(record.data.Website)
                // }
              />
            </Grid>
            <Grid item xs={3}>
              <InputRadio
                radios={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' }
                ]}
                FormControlLabelProps={{
                  label: '501c (Tax Exempt)?'
                }}
                RadioGroupProps={{
                  name: 'TaxExempt',
                  row: true,
                  disabled: shouldBeDisabled,
                  onChange,
                  value: record.data.TaxExempt || ''
                }}
                customTheme={disabledTheme}
              />
            </Grid>
            {/* </GridFillMaxHolder> */}
            <Grid
              item
              xs={12}
              sx={{ fontSize: '14px', fontWeight: 'bold', marginTop: '3px' }}
            >
              Applicable Category
            </Grid>
            <Grid item xs={12}>
              <InputRadio
                radios={[
                  { value: 'Community', label: 'Community' },
                  { value: 'Education', label: 'Education' },
                  { value: 'Environment', label: 'Environment' },
                  { value: 'Health', label: 'Health' },
                  { value: 'Veteran', label: 'Veteran' },
                  { value: 'Other', label: 'Other' }
                ]}
                FormControlLabelProps={{
                  label: 'Donation Category'
                }}
                RadioGroupProps={{
                  name: 'DonationCategory',
                  row: true,
                  disabled: shouldBeDisabled,
                  onChange,
                  value: record.data.DonationCategory || ''
                }}
                customTheme={disabledTheme}
              />
            </Grid>
            <Grid
              item
              xs={12}
              hidden={
                typeof record.data.DonationCategory === 'undefined' ||
                !(record.data.DonationCategory.indexOf('Other') > -1)
              }
            >
              <InputText
                label='Please Define'
                name='OtherCategory'
                onChange={onChange}
                value={record.data.OtherCategory || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

OrganizationInformation.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  config: PropTypes.objectOf(PropTypes.any),
  shouldBeDisabled: PropTypes.bool,
  disabledTheme: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  schema: PropTypes.objectOf(PropTypes.any).isRequired,
  fullyReviewed: PropTypes.func
};
OrganizationInformation.defaultProps = {
  record: {},
  config: {},
  shouldBeDisabled: false,
  disabledTheme: {},
  onChange: () => {},
  fullyReviewed: () => false
};

export default OrganizationInformation;
